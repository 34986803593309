<template>
  <el-main>
    <el-button style="margin-bottom: 15px" type="primary" class="el-icon-plus" size="small" @click="add">添加分类</el-button>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="类目名称：">
        <el-input v-model="name" size="small"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="15px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" row-key="id" :header-cell-style="{ 'background-color': '#F8F9FA' }" :tree-props="{ children: '_child', hasChildren: 'hasChildren' }">
      <el-table-column prop="name" label="分类名称" align="center">
        <template v-slot="{ row }">
          <span :style="{ 'font-weight': row._child ? 'bold' : '' }">{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="grade" label="分类等级" align="center">
        <template v-slot="{ row }">
          <span :style="{ 'font-weight': row._child ? 'bold' : '' }">{{ row.grade }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="120">
        <template #header>
          <div class="edit">
            <span>排序</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input size="small" type="Number" :min="0" @change="editChange(scope.row)" v-model="scope.row.sort"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="remove(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加/编辑分类" :visible.sync="showAdd" width="600px">
      <el-form class="add-form" label-width="100px">
        <el-form-item class="item" label="分类名称：">
          <el-input v-model="form.name" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item class="item" label="上级分类：">
          <el-select style="width: 200px" v-model="form.oneTypeId" :disabled="disabled_type" clearable placeholder="一级" @change="getSecondType">
            <el-option v-for="item in list" :key="item.id" :disabled="disabled_type" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select style="width: 200px" v-model="form.secondTypeId" :disabled="disabled_type" clearable placeholder="二级">
            <el-option v-for="item in secondType" :key="item.id" :disabled="disabled_type" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <p>不选择表示为最上级分类</p>
        </el-form-item>
        <el-form-item class="item" label="排序：">
          <el-input type="number" :min="0" v-model="form.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="confirmAdd">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      list: [],
      secondType: [],
      form: {
        id: 0,
        name: '',
        sort: '',
        parent_id: '',
        oneTypeId: '',
        secondTypeId: '',
      },
      showAdd: !1,
      disabled_type: !1,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getSecondType(val) {
      let data = this.list.find(item => item.id == val);
      this.secondType = data._child || [];
    },
    add() {
      this.form = {
        id: 0,
        name: '',
        sort: '',
        parent_id: '',
        oneTypeId: '',
        secondTypeId: '',
      };
      this.showAdd = !0;
    },
    remove(row) {
      this.$confirm('确认删除此分类？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.serviceProvider.enterprise.delEnterpriseCate, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.getList();
              this.$message.success('删除成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    edit(row) {
      let obj = Object.assign({}, row);
      let list = this.list;
      if (!obj.parent_id) obj.parent_id = '';
      obj.grade == '一级' ? (this.disabled_type = !0) : (this.disabled_type = !1);
      if (obj.id && obj.parent_id) {
        for (let i in list) {
          for (let y in list[i]._child) {
            if (list[i]._child[y].id == obj.id) {
              obj.oneTypeId = list[i].id;
            }
            for (let k in list[i]._child[y]._child) {
              if (list[i]._child[y]._child[k].id == obj.id) {
                obj.oneTypeId = list[i].id;
                obj.secondTypeId = list[i]._child[y].id;
              }
            }
          }
        }
      }
      if (obj.parent_id) {
        let data = this.list.find(item => item.id == obj.oneTypeId);
        this.secondType = data._child;
      }
      this.form = obj;
      this.showAdd = !0;
    },
    editChange(row) {
      this.$axios
        .post(this.$api.serviceProvider.enterprise.editEnterpriseCate, {
          id: row.id,
          parent_id: row.parent_id,
          name: row.name,
          sort: row.sort,
        })
        .then(res => {
          if (res.code == 0) {
            console.log('修改成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    confirmAdd() {
      if (!this.form.name) return this.$message.warning('请填写分类名称');
      if (this.form.oneTypeId) this.form.parent_id = this.form.oneTypeId;
      if (this.form.secondTypeId) this.form.parent_id = this.form.secondTypeId;
      let data = {
        sort: this.form.sort || 99,
        parent_id: this.form.parent_id || 0,
        name: this.form.name,
      };
      if (this.form.id) data.id = this.form.id;
      let url = data.id ? this.$api.serviceProvider.enterprise.editEnterpriseCate : this.$api.serviceProvider.enterprise.addEnterpriseCate;
      this.$axios.post(url, data).then(res => {
        if (res.code == 0) {
          this.showAdd = !1;
          this.getList();
          this.$message.success(data.id ? '编辑成功' : '添加成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      this.$axios
        .post(this.$api.serviceProvider.enterprise.enterpriseCateList, {
          name: this.name,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
            let list = res.result;
            list.map(item => {
              item.grade = '一级';
              if (item._child) {
                item._child.map(now => {
                  now.grade = '二级';
                  if (now._child) now._child.map(row => (row.grade = '三级'));
                });
              }
            });
            this.list = list;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    /deep/ .el-input__inner {
      border-color: #fff;
    }

    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }

    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }

  .edit {
    display: flex;
    align-items: center;

    span {
      margin-right: 5px;
    }

    i {
      font-size: 17px;
    }
  }
  .add-form {
    .el-form-item {
      .el-input,
      .el-select {
        margin-right: 10px;
      }
    }
  }
}
/deep/ .el-table__expand-icon--expanded {
  // 这是点击后的旋转角度
  transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
  .el-icon-arrow-right:before {
    //这是收起图标
    content: '\e723';
    font-size: 16px;
  }
}

/deep/ .el-table__expand-icon--expanded {
  .el-icon-arrow-right:before {
    // 这是展开图标
    content: '\e722';
    font-size: 16px;
  }
}
</style>